import { type } from "@testing-library/user-event/dist/type";

const { ethers,Contract,parseUnits  } = require("ethers");
const { abi } = require("./abi.json");
const { getTokenIn, getEthIn } = require("./basesaleFunctions");
const contractAddress = "0x50d9187E8cC1A27a43EF6D08686ede1e72122E15"; // Replace with actual contract address
const provider = new ethers.JsonRpcProvider(
  "https://base-rpc.publicnode.com/"
); //We will use our own RPC
 
const ERC20 = require("./swapping/build/ERC20.json"); //DO NOT CHANGE Erc20 token not lp
const PAIR = require("./swapping/build/IUniswapV2Pair.json"); 

const feePercent = 2;
const FACTORY = require("./swapping/build/IUniswapV2Factory.json");
const ROUTER = require("./swapping/build/UniswapV2Router02.json"); 

const contract = new ethers.Contract(contractAddress, abi, provider);
const contractUni = new ethers.Contract("0x9C9Dfc8b5D8F1cb1b7f854108Db16BE1C21ea400", FACTORY.abi, provider);


export async function getAmountOut(
  address1,
  address2,
  amountIn,
  
) {
  try {
    const RouterSwap2 = new ethers.Contract("0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7", ROUTER.abi, provider);

    const token1 = new Contract(address1, ERC20.abi, provider);
    const token1Decimals = await getDecimals(token1);
    const token2 = new Contract(address2, ERC20.abi, provider);
    const token2Decimals = await getDecimals(token2);
    const values_out = await RouterSwap2.getAmountsOut(
      parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );
    console.log(typeof values_out[1] )
    const amountOutBigInt = BigInt(values_out[1].toString());

    // Define a scaling factor equivalent to 10^18 as BigInt
    const scaleFactor = BigInt(10 ** 18);

    // Multiply by scaleFactor to simulate `10 ** -18` precision, then divide by the same to scale down
    const amount_out = (values_out[1] * scaleFactor) / BigInt(10 ** 18);

    return Number(amount_out) / Number(10 ** 18);
  } catch(e) {
    console.log(e)
    return false;
  }
}

export async function getBalanceAndSymbol(
  address,
  weth_address,
  signer
) {
  const accountAddress = await signer.getAddress();
  try {
    if (address === weth_address) {
      const balanceRaw = await signer.getBalance(accountAddress.toString());
      return {
        balance: ethers.utils.formatEther(balanceRaw),
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const balanceRaw = await token.balanceOf(accountAddress.toString());
      return Number(balanceRaw / BigInt(10 ** 18))
        

    }
  } catch (error) {
    console.log("The getBalanceAndSymbol function had an error!");
    console.log(error);
    return false;
  }
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error setting to 18", error);
      return 18;
    });
  return decimals;
}



export async function swapTokens(
  address1,
  address2,
  amount,
  signer,
  slippage // Slippage represented in basis points (e.g., 50 for 0.5%)
) {
  const RouterSwap2 = new ethers.Contract("0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7", ROUTER.abi, signer);


  let accountAddress = await signer.getAddress();

  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = BigInt(time);

  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);
  console.log(amount)
  // Convert amount to BigInt and handle decimals
  const amountIn = BigInt(Math.round((amount) * (10 ** 18)));
  console.log(amountIn)
  const amountOut = await RouterSwap2.getAmountsOut(
    amountIn,
    tokens
  );
  console.log(amountOut)

  const wethAddress = await RouterSwap2.WETH();

  const pairAddress = await contractUni.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);

  if (address1 === wethAddress) {
    // Buy operation
    const buyTotalFee = await pairContract.buyTotalFee();
    const liqFee = await RouterSwap2.usdcToEth(buyTotalFee);

    // Calculate amountOutMin using integer arithmetic
    const amountOutMin = (amountOut[1] * BigInt(Math.round(10000 - slippage))) / BigInt(10000);
    const totalValue = liqFee + amountIn;
console.log(amountOutMin)
console.log(totalValue)
    await RouterSwap2.swapExactETHForTokensSupportingFeeOnTransferTokens(
      amountOutMin,
      tokens,
      accountAddress,
      deadline,
      { value: totalValue }
    );
  } else if (address2 === wethAddress) {
    // Sell operation
    const token2 = new Contract(address1, ERC20.abi, signer);
    console.log(accountAddress)
    console.log(RouterSwap2)
    const allowance1 = await token2.allowance(
      accountAddress,
      '0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7'
    );

    if (allowance1 < amountIn) {
      console.log("Approving token transfer...");
      let tx = await token1.approve(
        '0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7',
        amountIn * BigInt(2)
      );
      await tx.wait();
    }

    const sellTotalFee = await pairContract.sellTotalFee();
    const liqFee = await RouterSwap2.usdcToEth(sellTotalFee);

    // Calculate amountOutMin using integer arithmetic
    const amountOutMin = (amountOut[1] * BigInt(Math.round(10000 - slippage))) / BigInt(10000);
    console.log(amountOutMin)
    const totalValue = (liqFee * BigInt(103)) / BigInt(100); // Equivalent to liqFee * 1.03
    await RouterSwap2.swapExactTokensForETHSupportingFeeOnTransferTokens(
      amountIn,
      amountOutMin,
      tokens,
      accountAddress.toString(),
      deadline,
      { value: totalValue }
    );
  }
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}
async function initializeEthers() {
  if (!window.ethereum) {
    alert("MetaMask is not installed. Please install it to use this dApp!");
    return null;
  }

  try {
    // Request account access if needed
    await window.ethereum.request({ method: "eth_requestAccounts" });

    // Create a new provider
    const provider = new ethers.BrowserProvider(window.ethereum);

    // Get the signer
    const signer = await provider.getSigner();

    return { provider, signer };
  } catch (error) {
    // console.error('User rejected the request:', error); // Removed console.log
    return null;
  }
}
export function getRouter(address, signer) {
  return new Contract(address.toString(), ROUTER.abi, signer);
}
//========== READ ONLY ==============

async function getPairAddressForBondedTokenForGraph(address1, address2) {
  try {
    console.log(address1)
    console.log(address2)

    const pairAdd = await contractUni.getPair(address1,address2)
    console.log("Pair Address:", pairAdd);
    return pairAdd;
  } catch (error) {
    console.error("Error fetching pair address:", error);
  }
}
async function getFirstBuyer(tokenAddress) {
  try {
    const firstBuyer = await contract.firstBuyer(tokenAddress);
    return firstBuyer;
  } catch (error) {
    // console.error('Error fetching buyer:', error); // Removed console.log
    throw error;
  }
}

async function getSale(tokenAddress) {
  try {
    const sale = await contract.sales(tokenAddress);
    return {
      creator: sale.creator,
      name: sale.name,
      symbol: sale.symbol,
      totalRaised: Number(sale.totalRaised) / 10 ** 18,
      saleGoal: Number(sale.saleGoal) / 10 ** 18,
      launched: sale.launched,
      nonce: sale.creationNonce,
    };
  } catch (error) {
    // console.error('Error fetching sale:', error); // Removed console.log
    throw error;
  }
}
async function getCreatorTokens(userAddress) {
  try {
    const creatorTokens = await contract.getCreatorTokens(userAddress);
    return creatorTokens;
  } catch (error) {
    // console.error('Error fetching user bought tokens:', error); // Removed console.log
    throw error;
  }
}

async function getSaleMetadata(tokenAddress) {
  try {
    const metadata = await contract.saleMetadata(tokenAddress);
    return {
      logoUrl: metadata.logoUrl,
      websiteUrl: metadata.websiteUrl,
      twitterUrl: metadata.twitterUrl,
      telegramUrl: metadata.telegramUrl,
      description: metadata.description,
    };
  } catch (error) {
    // console.error('Error fetching sale metadata:', error); // Removed console.log
    throw error;
  }
}

async function getUserBoughtTokens(userAddress) {
  try {
    const boughtTokens = await contract.getUserBoughtTokens(userAddress);
    return boughtTokens;
  } catch (error) {
    // console.error('Error fetching user bought tokens:', error); // Removed console.log
    throw error;
  }
}

//=========== WRITE ==================
async function createSale(
  name,
  symbol,
  logoUrl,
  websiteUrl,
  twitterUrl,
  telegramUrl,
  description,
  ethAmount,
  signer
) {
  try {
    const connection = await initializeEthers();
    if (!connection) return;

    const { provider, signer } = connection;
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const contractWithSigner = await contract.connect(signer);
    const weiAmount = BigInt(Math.round(ethAmount * 10 ** 18));
    const overrides = {
      value: weiAmount,
    };

    const tx = await contractWithSigner.createSale(
      name,
      symbol,
      logoUrl,
      websiteUrl,
      twitterUrl,
      telegramUrl,
      description,
      overrides
    );

    const receipt = await tx.wait();
    return receipt.hash;
  } catch (error) {
    // console.error('Error creating sale:', error); // Removed console.log
    throw error;
  }
}
async function calculateTokensForEth(ethAmount) {
  const k = 0.222; // adjusted liquidity factor
  const alpha = 2.878 * 10 ** -9; // adjusted scaling factor

  // Calculate tokens based on the formula
  const tokens = Math.log(ethAmount / k + 1) / alpha;

  return tokens;
}
async function buyToken(tokenAddress, ethAmount, slippage, signer, firstBuy) {
  try {
    const contractWithSigner = contract.connect(signer);
    //const saleAddress = (await getSale(tokenAddress)).saleContract;
    /* global BigInt */

    const fee = (ethAmount * feePercent) / 100;
    const amountAfterFee = ethAmount - fee;
    let tokensToBuy;
    if (firstBuy === true) {
      tokensToBuy = await getTokenIn(tokenAddress, Number(amountAfterFee));
    } else {
      tokensToBuy = await calculateTokensForEth(amountAfterFee);
    }
    const minTokensOut = BigInt(Math.round(tokensToBuy * (1 - slippage / 100)));

    const tx = await contractWithSigner.buyToken(tokenAddress, minTokensOut, {
      value: BigInt(Math.round(ethAmount * 10 ** 18)),
    });
    await tx.wait();
    return tx.hash;
  } catch (error) {
     console.error('Error buying tokens:', error); // Removed console.log
    throw error;
  }
}

async function sellToken(tokenAddress, tokenAmount, slippage, signer) {
  try {
    const contractWithSigner = contract.connect(signer);
    //const saleAddress = (await getSale(tokenAddress)).saleContract;
    const ethOut = await getEthIn(tokenAddress, Number(tokenAmount));
    const minEthOut = BigInt(Math.round(ethOut * (1 - slippage / 100)));

    const tx = await contractWithSigner.sellToken(
      tokenAddress,
      BigInt(Math.round(tokenAmount * 10 ** 18)),
      minEthOut
    );
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error selling tokens:', error); // Removed console.log
    throw error;
  }
}

async function claim(tokenAddress, signer) {
  try {
    const contractWithSigner = contract.connect(signer);
    const tx = await contractWithSigner.claim(tokenAddress);
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error claiming tokens:', error); // Removed console.log
    throw error;
  }
}

async function setSaleMetadata(
  tokenAddress,
  logoUrl,
  websiteUrl,
  twitterUrl,
  telegramUrl,
  description,
  signer
) {
  try {
    const contractWithSigner = contract.connect(signer);
    const tx = await contractWithSigner.setSaleMetadata(
      tokenAddress,
      logoUrl,
      websiteUrl,
      twitterUrl,
      telegramUrl,
      description
    );
    await tx.wait();
    return tx.hash;
  } catch (error) {
    // console.error('Error setting sale metadata:', error); // Removed console.log
    throw error;
  }
}
export {
  getSale,
  getFirstBuyer,
  getSaleMetadata,
  getUserBoughtTokens,
  createSale,
  buyToken,
  sellToken,
  claim,
  setSaleMetadata,
  getCreatorTokens,
  getPairAddressForBondedTokenForGraph,
};
